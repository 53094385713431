import "./App.css";
import {
  Achievements,
  Clinics,
  Facilities,
  Footer,
  Header,
  Navbar,
  Questions,
  Specialty,
  Main,
  Testimonials,
  Appointment,
} from "./components";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Main />
      <Achievements />
      <Facilities />
      <Specialty />
      <Questions />
      {/* <Testimonials /> */}
      <Appointment />
      <Clinics />
      <Footer />
    </div>
  );
}

export default App;
