import React from "react";
import "./styles.css";
import Group from "../../assets/Group.png";
import Home from "../../assets/Home.png";
import Med from "../../assets/Med.png";
function Achievements() {
  return (
    <div className="achievements">
      <div className="achievements-sub">
        <div className="achievements-sub_container">
          <div className="achievements-sub_image">
            <img src={Group} alt="Group" />
          </div>
          <div className="achievements-sub_text">
            <h1>+1200</h1>
            <h2>Doctors</h2>
          </div>
        </div>
        {/* <div className="achievements-sub_textTwo">
          <h3>
            Maecenas nisl libero, tincidunt id odio id, feugiat vulputate quam.{" "}
          </h3>
        </div> */}
      </div>
      <div className="achievements-sub">
        <div className="achievements-sub_container">
          <div className="achievements-sub_image">
            <img src={Home} alt="Home" />
          </div>
          <div className="achievements-sub_text">
            <h1>+1200</h1>
            <h2>Clinics</h2>
          </div>
        </div>
        <div></div>
      </div>
      <div className="achievements-sub">
        <div className="achievements-sub_container">
          <div className="achievements-sub_image">
            <img src={Med} alt="Medical Kit" />
          </div>
          <div className="achievements-sub_text">
            <h1>+100</h1>
            <h2>Specialist</h2>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Achievements;
