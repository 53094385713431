import React from "react";
import "./styles.css";
import Background from "../../assets/Background.png";
import Doctors from "../../assets/Doctors.png";

function Questions() {
  return (
    <div className="question" id="questions">
      <div className="question-container">
        <div className="question-container_left">
          <h1>Ask Doctors</h1>
          <div className="question-link_container">
            <p>
              <a href="#menu1">All</a>
            </p>
            <p>
              <a href="#menu2">Orthopedic</a>
            </p>
            <p>
              <a href="#menu3">Nutritionist</a>
            </p>
            <p>
              <a href="#menu4">Pediatric</a>
            </p>
            <p>
              <a href="#menu5">Anaesthestic</a>
            </p>
          </div>
        </div>
        <div className="question-container_right">
          <img src={Doctors} alt="doctors" />
          <div className="question-container_right-background">
            <img src={Background} alt="background" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Questions;
