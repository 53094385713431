import React from "react";
import "./styles.css";
import Logo from "../../assets/Logo.png";
import Shape from "../../assets/Shape.png";

function Navbar() {
  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="navbar-links-name">
          <p>Medical</p>
        </div>
        <div className="navbar-links_container">
          <p>
            <a href="#Home">Menu1</a>
          </p>
          <p>
            <a href="#facilities">Menu2</a>
          </p>
          <p>
            <a href="#questions">Menu3</a>
          </p>
          <p>
            <a href="#clinics">Menu4</a>
          </p>
          <p>
            <a href="#footer">Menu5</a>
          </p>
        </div>
      </div>
      <div className="navbar-signIn">
        <p>Login</p>
        <button type="button">Register</button>
        <div className="navbar-signIn_vertical" />
        <img src={Shape} alt="settings" />
      </div>
    </div>
  );
}

export default Navbar;
