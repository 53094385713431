import React from "react";
import Logo from "../../assets/Logo.png";
import "./styles.css";
import SocialOne from "../../assets/SocialOne.png";
import SocialTwo from "../../assets/SocialTwo.png";
import SocialThree from "../../assets/SocialThree.png";
import SocialFour from "../../assets/SocialFour.png";
function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="footer-links_logo">
        <div className="footer-links_logo-row">
          <img src={Logo} alt="gpt3_logo" />
          <h1>Medical</h1>
        </div>
        <p>
          Nam posuere accumsan porta. Integer id tincidunt sit amet sed libero.
        </p>
        <p>© Skyrev Theme 2021</p>
      </div>
      <div className="footer-links_div">
        <h4>COMPANY</h4>
        <p>Donec dignissim</p>
        <p>Curabitur egestas </p>
        <p>Nam posuere </p>
        <p>Aenean facilisis</p>
      </div>
      <div className="footer-links_div">
        <h4>Services</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="footer-links_div">
        <h4>resources</h4>
        <p>Crechterwoord K12 182 DK Alknjkcb</p>
        <p>085-132567</p>
        <p>info@payme.net</p>
      </div>
      <div className="footer-links_social">
        <div className="footer-links_social-row">
          <img src={SocialOne} alt="SocialMedia" />
          <img src={SocialTwo} alt="SocialMedia" />
          <img src={SocialThree} alt="SocialMedia" />
          <img src={SocialFour} alt="SocialMedia" />
        </div>
        <div>
          
        </div>
      </div>
    </div>
  );
}

export default Footer;
