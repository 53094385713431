import React from "react";
import "./styles.css";
import Doctor from "../../assets/Doctor.png";

function Specialty() {
  const data = [
    {
      id: 1,
      name: "Dentist",
    },
    {
      id: 2,
      name: "Padriatic",
    },
    {
      id: 3,
      name: "Cardiology",
    },
    {
      id: 4,
      name: "Traumatologi",
    },
    {
      id: 5,
      name: "Othopaedi",
    },
    {
      id: 6,
      name: "Anestesiologi",
    },
    {
      id: 7,
      name: "Reumatologi",
    },
    {
      id: 8,
      name: "Andrologi",
    },
    {
      id: 9,
      name: "Ortodonsia",
    },
  ];
  return (
    <div className="specialty">
      <div className="specialty-container">
        <div className="specialty-container_left">
          <h1>Our Speciality</h1>
          <div className="specialty-container_left-paragraph">
            <p>
              Curabitur egestas consequat lorem, vel fermentum augue porta id.
              Aliquam lobortis magna neque, gravida consequat velit venenatis
              at.
            </p>
          </div>
          <div className="specialty-bubble_container">
            <div className="specialty-bubble_container">
              {data.map((item) => (
                <div className="specialty-bubble">{item.name}</div>
              ))}
            </div>
          </div>
        </div>
        <div className="specialty-container_right">
          <div className="specialty-container_right-image">
            <img src={Doctor} alt="Doctor" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Specialty;
