import React from "react";
import "./styles.css";

function Appointment() {
  return (
    <div className="appointment">
      <div className="appointment-container">
        <h1>Ready to get started ?</h1>
        <h2>Pellentesque ac bibendum tortor. Nulla eget lobortis lacus.</h2>
      </div>
      <div className="appointment-container_btn">
        <button type="button">Create Appointment Now!</button>
      </div>
    </div>
  );
}

export default Appointment;
