import React from "react";
import "./styles.css";
import Stamp from "../../assets/pngegg.png";
import EquipmentOne from "../../assets/EquipmentOne.png";
import EquipmentTwo from "../../assets/EquipmentTwo.jpg";
import EquipmentThree from "../../assets/EquipmentThree.jpg";
import { IoIosArrowRoundForward } from "react-icons/io";

function Facilities() {
  return (
    <div className="facilities" id="facilities">
      <div className="facilities-top">
        <div className="facilities-top_first">
          <h1>Clinic Facilities</h1>
          <h3>
            Maecenas nisl libero, tincidunt id odio id, feugiat vulputate quam.
          </h3>
        </div>
        <div className="facilities-top_two">
          <button type="button">See All</button>
          <IoIosArrowRoundForward className="facilities-top_two-icon" />
        </div>
      </div>
      <div className="facilities-bottom">
        <div className="facilities-bottom_image">
          <img src={Stamp} alt="Facilities" />
        </div>
        <div className="facilities-bottom_card">
          <div className="facilities-bottom_card-one">
            <div className="facilities-bottom_card-image">
              <img src={EquipmentOne} alt="cardOne" />
            </div>
            <div className="facilities-bottom_card-content">
              <h3>lorem ipsum dolor</h3>
              <p>
                Nam sollicitud nunc, cursus eros vulputate sed. Vestibulum
                lobortis.
              </p>
            </div>
            <div className="facilities-bottom_card-content-bottom_container">
              <div className="facilities-bottom_card-content-bottom">
                <h3>See Detail</h3>
              </div>
            </div>
          </div>
          <div className="facilities-bottom_card-one">
            <div className="facilities-bottom_card-image">
              <img src={EquipmentTwo} alt="cardTwo" />
            </div>
            <div className="facilities-bottom_card-content">
              <h3>lorem ipsum dolor</h3>
              <p>
                Nam sollicitud nunc, cursus eros vulputate sed. Vestibulum
                lobortis.
              </p>
            </div>
            <div className="facilities-bottom_card-content-bottom_container">
              <div className="facilities-bottom_card-content-bottom">
                <h3>See Detail</h3>
              </div>
            </div>
          </div>
          <div className="facilities-bottom_card-one">
            <div className="facilities-bottom_card-image">
              <img src={EquipmentThree} alt="cardThree" />
            </div>
            <div className="facilities-bottom_card-content">
              <h3>lorem ipsum dolor</h3>
              <p>
                Nam sollicitud nunc, cursus eros vulputate sed. Vestibulum
                lobortis.
              </p>
            </div>
            <div className="facilities-bottom_card-content-bottom_container">
              <div className="facilities-bottom_card-content-bottom">
                <h3>See Detail</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facilities;
