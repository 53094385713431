import React from "react";
import "./styles.css";

function Main() {
  return (
    <div className="main">
      <div className="main-content">
        <div className="main-content_sub">
          <h2>Business Solution</h2>
          <p>Interdum et malesuada fames ac ante…</p>
        </div>
        <div className="main-vertical" />
        <div className="main-content_sub">
          <h2>Free project quote</h2>
          <p>Interdum et malesuada fames ac ante…</p>
        </div>
        <div className="main-vertical" />
        <div className="main-content_sub">
          <h2>Nulla lobortis nunc</h2>
          <p>Interdum et malesuada fames ac ante…</p>
        </div>
      </div>
    </div>
  );
}

export default Main;
