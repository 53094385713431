import React from "react";
import "./styles.css";
import RectangleOne from "../../assets/RectangleOne.png";
// import RectangleTwo from "../../assets/RectangelTwo.png";
// import RectangleThree from "../../assets/RectangelThree.png";
// import RectangleFour from "../../assets/RectangelFour.png";
// import RectangleFive from "../../assets/RectangelFive.png";

function Clinics() {
  const data = [
    {
      id: 1,
      name: "Heaq Quarters",
      phone: "+123 456 78 91",
      email: "hello@luxi.com",
      address: "Lorem ipsum street no 14 Block A",
    },
    {
      id: 2,
      name: "Heaq Quarters",
      phone: "+123 456 78 91",
      email: "hello@luxi.com",
      address: "Lorem ipsum street no 14 Block A",
    },
    {
      id: 3,
      name: "Heaq Quarters",
      phone: "+123 456 78 91",
      email: "hello@luxi.com",
      address: "Lorem ipsum street no 14 Block A",
    },
    {
      id: 4,
      name: "Heaq Quarters",
      phone: "+123 456 78 91",
      email: "hello@luxi.com",
      address: "Lorem ipsum street no 14 Block A",
    },
    {
      id: 5,
      name: "Heaq Quarters",
      phone: "+123 456 78 91",
      email: "hello@luxi.com",
      address: "Lorem ipsum street no 14 Block A",
    },
    {
      id: 6,
      name: "Heaq Quarters",
      phone: "+123 456 78 91",
      email: "hello@luxi.com",
      address: "Lorem ipsum street no 14 Block A",
    },
  ];
  return (
    <div className="clinics" id="clinics">
      <div className="clinics-container">
        <h1>Our Clinics</h1>
        <p>
          Nam sollicitudin dignissim nunc, cursus ullamcorper eros vulputate
          sed.
        </p>

        <div className="clinics-bubble_container">
          {data.map((item) => (
            <div className="clinics-bubble">
              <div className="clinics-bubble_image">
                <img src={RectangleOne} alt="stamp" />
              </div>
              <div className="clinics-bubble_content">
                <h3>{item.name}</h3>
                <div className="clinics-bubble_phone">
                  <p>{item.phone}</p>
                  <p>{item.email}</p>
                </div>
                <p>{item.address}</p>

                <div className="clinics-bubble_container-btn">
                  <button type="button">Connect</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Clinics;
