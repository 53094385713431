import React from "react";
import "./styles.css";
// import Doctor from "../../assets/Doctor.png";

function Testimonials() {
  const data = [
    {
      id: 1,
      name: "Nam sollicitud nunc, cursus eros vulputate sed. Vestibulum lobortis.",
    },
    {
      id: 2,
      name: "Nam sollicitud nunc, cursus eros vulputate sed. Vestibulum lobortis.",
    },
  ];
  return (
    <div className="testimonials">
      <div className="testimonials-container">
        <div className="testimonials-container_header">
          <h1>People Testimonial</h1>
          <h3>
            Nam sollicitudin dignissim nunc, cursus ullamcorper eros vulputate
            sed.
          </h3>
        </div>
        <div className="testimonials-container_content">
          <div className="testimonials-container_content-list">
            {data.map((item) => (
              <div className="testimonials-container_content-card">
                <p>{item.name}</p>
              </div>
            ))}
          </div>
          <div className="testimonials-container_content-imag"></div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
